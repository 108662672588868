<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="companyId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Şirket Mesken"
            icon="mdi-fireplace-off"
            @click:edit="handleEditClick"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.cluster="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.clusters.show',
              params: { id: item.cluster_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.name="{ item, value }">
          <router-link
            v-if="value"
            :to="{
              name: 'definitions.residents.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.door_number="{ item, value }">
          <router-link
            v-if="value && can('see-house')"
            :to="{
              name: 'definitions.houses.show',
              params: { id: item.house_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>
            {{ value }}
          </template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_balance="{ value }">
          <rs-table-cell-balance colored :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.enforcement_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir yönetim şirketi seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasPermissions],
  computed: {
    ...mapGetters(["companyId", "company", "cluster", "houseUserTypeList"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    companyId: {
      handler() {
        this.loadList();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadURLParams();
    });
  },
  data() {
    return {
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("see-house-income"),
        },
        {
          text: "TYA Adı",
          value: "cluster",
          searchable: "multiselect",
          options: () => this.company.clusters,
          width: "175px",
        },
        {
          text: "Blok",
          value: "block",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: "Adı Soyadı",
          value: "name",
          searchable: "text",
          width: "155px",
        },
        {
          text: this.$t("labels.identity_no"),
          value: "identity_number",
          searchable: "text",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          options: () => this.houseUserTypeList,
        },
        {
          text: "Araçlar",
          value: "vehicles",
          searchable: "text",
        },
        {
          text: "Bakiye",
          value: "resident_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "İcra Tutar",
          value: "enforcement_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Bakiye-İcra",
          value: "enforcement_balance",
          searchable: "number",
          align: "end",
        },
        {
          text: "Telefon",
          value: "phone",
          searchable: "text",
        },
        {
          text: "E-posta",
          value: "email",
          searchable: "text",
        },
      ],
    };
  },
  methods: {
    loadList() {
      this.selectedItems = [];
      if (this.isLoading || !this.companyId) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`companies/${this.companyId}/residents`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "incomes.residents.show",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
  },
};
</script>
